.footer{
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(19,19,19);
    color: aliceblue;
}

.footer_content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: auto;
}

.contact-btn {
    margin-bottom: 20px;
}

.copyright {
    text-align: center;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

p {
    margin: 3px;
}

@media (max-width: 350px) {
    .footer {
        flex-direction: column;
    }
}

@media (max-width: 440px) {
    .adress {
        border-right: 1px solid white;
    }

    .info {
        border-left: 1px solid white;
    }
}